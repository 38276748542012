import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  container,
  navLinks,
  navLinkItem,
  navLinkText,
  siteTitle,
  footer,
  imghehe
} from './layout.module.css'
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
deckDeckGoHighlightElement();

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <main id="blog" className={container}>
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <div className={siteTitle}>{pageTitle || data.site.siteMetadata.title}</div>
      <nav>
        <ul className={navLinks}>
          <li className={navLinkItem}>
            <Link to="/" className={navLinkText}>
              journal
            </Link>
          </li>
          {/* <li className={navLinkItem}>
            <a href="https://www.redbubble.com/people/enzozzz123/shop?asc=u&ref=account-nav-dropdown" target="_blank" className={navLinkText}>shop</a>
          </li> */}
          {/* <li className={navLinkItem}>
            <Link to="/wall" className={navLinkText}>
              Wall
            </Link>
          </li> */}
          <li className={navLinkItem}>
            <Link to="/wall" className={navLinkText}>
              wall
            </Link>
          </li>
        </ul>
      </nav>
      {children}
      <footer className={footer}>Yang Fei © 2023</footer>
    </main>
  )
}

export default Layout